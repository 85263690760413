export default {
  v: '5.8.1',
  fr: 24,
  ip: 0,
  op: 72,
  w: 1000,
  h: 1000,
  nm: '获奖动画',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 1000,
      h: 1000,
      u: '/assets/lottie/luckyspin/',
      p: 'box1.jpg',
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: '优惠卷',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [500, 500, 0], ix: 2, l: 2},
        a: {a: 0, k: [500, 500, 0], ix: 1, l: 2},
        s: {
          a: 1,
          k: [
            {
              i: {x: [0.684, 0.684, 0.667], y: [1, 1, 1]},
              o: {x: [0.04, 0.04, 0.333], y: [0, 0, 0]},
              t: 0,
              s: [1, 1, 100],
            },
            {t: 12, s: [100, 100, 100]},
          ],
          ix: 6,
          l: 2,
          x: "var $bm_rt;\nvar amp, freq, decay, n, t, v;\ntry {\n    amp = $bm_div(effect('缩放 - Overshoot')('ADBE Slider Control-0001'), 2.5), freq = $bm_div(effect('缩放 - Bounce')('ADBE Slider Control-0001'), 20), decay = $bm_div(effect('缩放 - Friction')('ADBE Slider Control-0001'), 20), n = 0, 0 < numKeys && (n = nearestKey(time).index, key(n).time > time && n--), t = 0 === n ? 0 : $bm_sub(time, key(n).time), $bm_rt = 0 < n ? (v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10))), $bm_sum(value, $bm_div($bm_mul($bm_mul($bm_div(v, 100), amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))))) : value;\n} catch (e$$4) {\n    $bm_rt = value = value;\n}",
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: '缩放 - Overshoot',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: '滑块',
              mn: 'ADBE Slider Control-0001',
              ix: 1,
              v: {
                a: 0,
                k: 80,
                ix: 1,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
          ],
        },
        {
          ty: 5,
          nm: '缩放 - Bounce',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 2,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: '滑块',
              mn: 'ADBE Slider Control-0001',
              ix: 1,
              v: {
                a: 0,
                k: 60,
                ix: 1,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
          ],
        },
        {
          ty: 5,
          nm: '缩放 - Friction',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 3,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: '滑块',
              mn: 'ADBE Slider Control-0001',
              ix: 1,
              v: {
                a: 0,
                k: 50,
                ix: 1,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
          ],
        },
        {
          ty: 5,
          nm: '缩放 - Overshoot',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 4,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: '滑块',
              mn: 'ADBE Slider Control-0001',
              ix: 1,
              v: {
                a: 0,
                k: 10,
                ix: 1,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
          ],
        },
        {
          ty: 5,
          nm: '缩放 - Bounce',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 5,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: '滑块',
              mn: 'ADBE Slider Control-0001',
              ix: 1,
              v: {
                a: 0,
                k: 40,
                ix: 1,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
          ],
        },
        {
          ty: 5,
          nm: '缩放 - Friction',
          np: 3,
          mn: 'ADBE Slider Control',
          ix: 6,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: '滑块',
              mn: 'ADBE Slider Control-0001',
              ix: 1,
              v: {
                a: 0,
                k: 10,
                ix: 1,
                x: 'var $bm_rt;\n$bm_rt = clamp(value, 0, 100);',
              },
            },
          ],
        },
      ],
      ip: 0,
      op: 120,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
